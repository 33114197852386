<template>
  <div class="run-det">
    <div class="r-box" @click="toHome()">
      <img src="@/assets/seize-img/info-left.png" alt="">
      <div class="" v-if="listData.status === 1">待接单</div>
      <div class="" v-else-if="listData.status === 2">待取货</div>
      <div class="" v-else-if="listData.status === 3">待送达</div>
      <div class="" v-else>已完成</div>
    </div>
    <div class="content-box">
      <div class="info-list">
        <div class="info-list-box">
          <div class="info-1">
            <img src="@/assets/seize-img/time-icon.png" alt="">
            <div class="">
              <span class="c-F87A08 fs-14 fw-600">{{listData.time_str}}</span>
              <span class="c-333 fs-14 f-w-600" v-if="listData.status !== 4">内送达</span>
              <span class="c-333 fs-14 f-w-600" v-else>已送达</span>
            </div>
            <div class="info-1-money"><span class="fs-12">￥</span><span class="fs-22 fw-600">{{listData.postage}}</span>
            </div>
          </div>

          <div class="info-2">
            <div class="info-2-left">
              <img src="@/assets/seize-img/qu-icon.png" alt="">
              <div class="">{{listData.distance*1>1000?(listData.distance*0.001).toFixed(2):listData.distance}}</div>
              <div class="">{{listData.distance*1>1000?'km':'m'}}</div>
              <div class="f-g-div"></div>
              <img src="@/assets/seize-img/song-icon.png" alt="">
              <div class="">{{listData.aadistance*1>1000?(listData.aadistance*0.001).toFixed(2):listData.aadistance}}
              </div>
              <div class="">{{listData.aadistance*1>1000?'km':'m'}}</div>
            </div>
            <div class="info-2-right">
              <div class="fs-18 c-333 f-w-600">{{listData.shop_name}}</div>
              <div class="fs-12 c-666">{{listData.city_name}}</div>
              <div class="fs-18 c-333 f-w-600 mt-20">{{listData.shop_name}}</div>
              <div class="fs-12 c-666">{{listData.city_names}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="goods-box">
        <div class="goods-title">
          商品信息
        </div>
        <div class="goods" v-for="(itemTwo) in listData.goods_list" :key="itemTwo.id">
          <div class="left">
            <img class="goods-img" :src="itemTwo.goods_img">
          </div>
          <div class="right">
            <div class="order-goods-right-top">
              <div class="order-goods-right-left">
                <div class="goods-name">
                  {{itemTwo.goods_name}}
                </div>
                <div class="goods-size-box">
                  <div class="goods-size-width goods-size">
                    {{itemTwo.item_key}}
                  </div>
                  <!-- 										<image v-show="item.item_key.length >=9" @click="goodsSizeInfo"
    							class="goods-size-img" src="@/static/order/down.png" mode=""></image> -->
                </div>
              </div>
              <div class="goods-number">
                X{{itemTwo.goods_num}}
              </div>
              <div class="order-goods-right-right">
                <div class="goods-price">
                  ￥{{itemTwo.price}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--        <div class="remark">
          <div class="remark-left">
            实付：
          </div>
          <div class="remark-right">
            ￥{{listData.total_money}}
          </div>
        </div> -->
      </div>
      <div class="details-list">
        <div class="goods-title">
          订单信息
        </div>
        <div class="list-two">
          <div class="left">订单编号</div>
          <div class="right">
            <span>{{listData.order_sn}}</span>
          </div>
        </div>
        <div class="list-two">
          <div class="left">期望送达</div>
          <div class="right">
            <span>{{listData.delivery_time}}</span>
          </div>
        </div>
        <div class="order-income">
          <div class="order-income-one">
            订单收入
          </div>
          <div class="order-income-two">
            你还未参与接单
          </div>
        </div>
        <div class="list-two">
          <div class="left">配送费</div>
          <div class="right">
            <span>￥{{listData.postage}}</span>
          </div>
        </div>
        <!--        <div class="list-two">
          <div class="left">合计</div>
          <div class="right">
            <span>￥{{listData.total_money}}</span>
          </div>
        </div> -->
      </div>
      <div class="details-list">
        <div class="goods-title">
          要求送达
        </div>
        <div class="time-box">
          <div class="time-box-one">
            <div class="time">
              <div class="">
                {{confirmTime(listData.rob_time,1)}}
              </div>
              <div class="" v-show="listData.rob_time">
                {{confirmTime(listData.rob_time,2)}}
              </div>
            </div>
            <div class="time-name">
              抢单
            </div>
          </div>
          <div class="time-box-one time-box-two">
            <div class="time">
              <div class="">
                {{confirmTime(listData.shipping_time,1)}}
              </div>
              <div class="" v-show="listData.shipping_time">
                {{confirmTime(listData.shipping_time,2)}}
              </div>
            </div>
            <div class="time-name">
              取货
            </div>
          </div>
          <div class="time-box-one time-box-three">
            <div class="time">
              <div class="">
                {{confirmTime(listData.confirm_time,1)}}
              </div>
              <div class="" v-show="listData.confirm_time">
                {{confirmTime(listData.confirm_time,2)}}
              </div>
            </div>
            <div class="time-name">
              送达
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-box">
      <div class="examineClass" @click="openPopup(listData.id)" v-if="listData.status == 2">
        申请改派
      </div>
      <div class="examine" v-if="listData.status == 1" @click="runRob(listData.id)">
        抢单
      </div>
      <div class="examine" v-if="listData.status == 2" @click="getCode(listData.order_sn)">
        确认取货
      </div>
      <div class="examine" v-if="listData.status == 3 " @click="runService(listData.id)">
        确认送达
      </div>
    </div>
    <van-popup v-model="popupShow" position="bottom" :style="{ height: '60%' }">
      <div class="popup-box">
        <div class="popup-title">
          申请改派
        </div>
        <img class="popup-gb" src="@/assets/order/gb.png" alt="" @click="gbPopup">
        <div class="popup-content">
          <div class="radio-tilte">
            请选择愿意
          </div>
          <div class="radio-box" v-for="(item,index) in reasonList" :key="index" @click="radio(item,index)">
            <div class="radio-left">
              {{item}}
            </div>
            <img class="radio-right" src="@/assets/order/ok@2x.png" alt="" v-if="reasonType == index">
            <div class="radio-right" v-else></div>
          </div>
          <div class="instructions">
            补充说明
          </div>
          <van-cell-group>
            <van-field type="textarea" v-model="note" placeholder="请输入补充说明选填" rows="2" autosize maxlength="50"
              class="van-field" />
          </van-cell-group>
          <div class="popup-but" @click="applyFor">
            确认申请
          </div>
        </div>
      </div>
    </van-popup>
    <div class="pop-box" v-show="show" @click.stop="closeQrCode()">
      <div class="pop-info">
        <div class="fs-18">取货码</div>
        <div class="codeimg" ref="qrCodeDiv"></div>
        <div class="fs-14">
          订单编号：{{order_sn}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    runOrderInfo,
    runRob,
    runService,
    runOrderRewrite
  } from "@/api/runOrder.js";
  import {
    Popup,
    Toast
  } from 'vant';
  import QRcode from 'qrcodejs2'
  export default {
    components: {},
    data() {
      return {
        listData: [],
        orderId: '',
        lon: '',
        lat: '',
        reason: '', //申请原因
        note: '', //备注
        popupShow: false,
        reasonList: ['交通公交出现故障', '遭遇交通事故', '身体突发不适', '单子多送过不来', '其他'],
        reasonType: -1,
        show: false,
        order_sn: '',
      }
    },
    mounted() {
      this.orderId = this.$route.query.order_id
      this.lon = this.$route.query.lon
      this.lat = this.$route.query.lat
      this.runOrderInfo()
    },
    methods: {
      confirmTime(item, type) {
        if (item) {
          if (type == 1) {
            return item.slice(0, 10)
          } else {
            return item.slice(10)
          }
        } else {
          return "-"
        }

      },
      getCode(item) {
        this.order_sn = item + ''
        let qrcode = new QRcode(this.$refs.qrCodeDiv, {
          text: item,
          width: 180,
          height: 180,
          colorDark: '#333333',
          colorLight: '#ffffff',
          correctLevel: QRcode.CorrectLevel.H,
        })
        this.show = true
      },
      closeQrCode() {
        this.show = false
        this.$refs.qrCodeDiv.innerHTML = ''
      },
      gbPopup() {
        this.popupShow = false
      },
      openPopup() {
        this.popupShow = true
      },
      applyFor() {
        if (this.reasonType == -1) {
          Toast.fail("请选择申请原因");
        } else {
          this.runOrderRewrite(this.orderId)
        }
      },
      radio(text, index) {
        this.reason = text
        this.reasonType = index
      },
      toHome() {
        this.$router.go(-1);
      },
      //改派
      runOrderRewrite(id) {
        runOrderRewrite({
          order_id: id,
          reason: this.reason,
          note: this.note,
        }).then((res) => {
          if (res.code === 1) {
            this.popupShow = false
            this.reasonType = -1
            this.note = ""
            Toast.success(res.msg);
            this.listData = []
            this.runOrderInfo()
          } else {
            Toast.fail(res.msg);
          }
        })
      },
      // 抢单
      runRob(id) {
        runRob({
          order_id: id
        }).then((res) => {
          if (res.code === 1) {
            Toast.success(res.msg);
            this.listData = []
            this.runOrderInfo()
          } else {
            Toast.fail(res.msg);
          }
        })
      },
      // 送达
      runService(id) {
        runService({
          order_id: id
        }).then((res) => {
          if (res.code === 1) {
            Toast.success(res.msg);
            this.listData = []
            this.runOrderInfo()
          } else {
            Toast.fail(res.msg);
          }
        })
      },
      runOrderInfo() {
        runOrderInfo({
          'order_id': this.orderId,
          'lon': this.lon,
          'lat': this.lat
        }).then((res) => {
          if (res.code == 1) {
            console.log(res.data);
            this.listData = res.data
          }
        })
      },
    },
  }
</script>

<style scoped lang="less">
  .run-det {

    .r-box {
      width: 100%;
      height: 44px;
      background: #fff;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding-left: 13px;

      img {
        width: 30px;
        height: 30px;
      }

      div {
        font-size: 16px;
        margin-left: 10px;
        font-weight: 600;
      }
    }

    .content-box {
      padding: 10px 12px 49px;
      box-sizing: border-box;

      .info-list {
        box-sizing: border-box;
        padding: 12px;

        .info-list-box {
          margin-bottom: 10px;
          width: 100%;
          min-height: 100px;
          background: #FFFFFF;
          border-radius: 10px;
          box-sizing: border-box;
          padding: 16px;

          .mt-20 {
            margin-top: 20px;
          }

          .c-F87A08 {
            color: #F87A08;
          }

          .c-333 {
            color: #333;
          }

          .c-666 {
            color: #666;
          }

          .fs-12 {
            font-size: 12px
          }

          .fs-14 {
            font-size: 14px
          }

          .fs-18 {
            font-size: 18px
          }

          .fs-22 {
            font-size: 22px
          }

          .fw-600 {
            font-weight: 600;
          }

          .info-1 {
            display: flex;
            align-items: center;
            position: relative;

            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }

            .info-1-money {
              position: absolute;
              right: 0;
              top: 0;

              span {
                color: #ED301D;
              }
            }
          }

          .info-2 {
            margin-top: 16px;
            width: 100%;
            display: flex;

            .info-2-left {
              width: 40px;
              min-height: 100px;
              background: #F6F6F6;
              border-radius: 100px;
              margin-right: 12px;
              box-sizing: border-box;
              padding: 8px 0;
              text-align: center;
              display: flex;
              flex-direction: column;

              .f-g-div {
                flex: 1;
                width: 1px;
                margin: 3px auto 5px auto;
                background-color: #ccc;
              }

              img {
                margin: auto;
                display: block;
                width: 20px;
                height: 20px;
              }
            }

            .info-2-right {
              box-sizing: border-box;
              padding: 6px 0;
              flex: 1;
              min-height: 100px;
            }
          }

          .infp-3 {
            width: 100%;
            height: 40px;
            background: linear-gradient(236deg, #FC5656 0%, #F70606 100%);
            border-radius: 100px;
            text-align: center;
            line-height: 40px;
            color: #fff;
          }
        }
      }

      .goods-title {
        margin: 0 0 17px 0;
        font-size: 16px;
        color: #333;
        font-weight: 600;
      }

      .goods-box {
        width: 100%;
        height: auto;
        padding: 16px 12px 0;
        margin: 0 0 10px;
        border-radius: 10px;
        background: #fff;
        box-sizing: border-box;
        justify-content: space-between;

        .goods {
          width: 100%;
          height: auto;
          display: flex;
          box-sizing: border-box;
          padding-bottom: 13px;

          .left {
            margin-right: 10px;

            .goods-img {
              width: 50px;
              height: 50px;
              display: block;
              border-radius: 6px;
            }
          }

          .right {
            width: 100%;
            height: auto;

            // box-sizing: border-box;
            .order-goods-right-top {
              width: 100%;
              height: auto;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .order-goods-right-left {
                .goods-name {
                  max-width: 272rpx;
                  text-overflow: -o-ellipsis-lastline;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  line-clamp: 2;
                  -webkit-box-orient: vertical;
                  font-size: 14px;
                  color: #333333;
                }

                .goods-size-box {
                  display: flex;

                  // align-items: center;
                  .goods-size-width {}

                  .goods-size {
                    max-width: 122px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    word-break: break-all;
                    font-size: 12px;
                    color: #9C9C9C;
                  }

                  .goods-size-style {
                    max-width: 122px;
                    font-size: 12px;
                    color: #9C9C9C;
                  }

                  .goods-size-img {
                    width: 11px;
                    height: 11px;
                    margin: 4px 0 0 0;
                  }
                }
              }

              .order-goods-right-right {
                .goods-price {
                  font-size: 15px;
                  font-weight: 500;
                  color: #333333;
                  font-weight: 600;
                }
              }

              .goods-number {
                font-size: 12px;
              }
            }
          }

        }

        .remark {
          display: flex;
          align-items: center;
          padding: 16px 0 13px 0;
          border-top: 1px solid #EEEEEE;
          margin: 0 0 17px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .remark-left {
            font-size: 14px;
            color: #333333;
          }

          .remark-right {
            font-size: 14px;
            color: #333;
          }
        }

        .list {
          // padding: 16rpx 0 16rpx 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0 18px 0;

          .left {
            // width: 114rpx;
            font-size: 14px;
            color: #333;
            font-weight: 400;
          }

          .right {
            font-size: 14px;
            color: #333;
            font-weight: 600;
          }

          .right-two {
            color: #FF3333;
          }
        }
      }

      .details-list {
        width: 100%;
        height: auto;
        background: #fff;
        border-radius: 10px;
        padding: 16px 12px 0;
        box-sizing: border-box;
        margin: 0 0 10px 0;

        .list-two {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0 15px 0;

          .left {
            font-size: 14px;
            color: #333333;
          }

          .right {
            font-size: 14px;
            color: #333;
            display: flex;
            align-items: center;
          }
        }

        .order-income {
          padding: 16px 0;
          border-top: 1px solid #EEEEEE;

          .order-income-one {
            font-size: 16px;
            color: #333333;
            font-weight: 600;
          }

          .order-income-two {
            color: #999999;
            font-size: 12px;
          }
        }

        .time-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 15px 16px;

          .time-box-one {
            text-align: center;

            .time {
              margin: 0 0 3px 0;
            }

            .time-name {}
          }

          .time-box-two {}

          .time-box-three {}
        }
      }
    }

    .button-box {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 49px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 12px;
      box-sizing: border-box;

      .but {
        width: 100%;
        height: 100%;
        background: #F81515;
        border-radius: 100px;
        line-height: 80rpx;
        text-align: center;
        font-size: 16px;
        color: #fff;
      }

      .examine {
        flex: 1;
        padding: 12px 13px;
        font-size: 16px;
        border-radius: 100px;
        text-align: center;
        background: #F81515;
        color: #fff;
        margin: 0 0 0 12px;
      }

      .examineClass {
        width: 115px;
        padding: 12px 13px;
        font-size: 16px;
        text-align: center;
        border-radius: 100px;
        background: #fff;
        color: #333;
        border: 1px solid #B9BABE;
      }
    }

    .popup-box {
      position: relative;
      background: #fff;
      // overflow: auto;
      overflow-y: scroll;

      .popup-title {
        padding: 20px 0;
        text-align: center;
        border-bottom: 1px solid #E0E0E0;
        font-size: 18px;
        color: #333333;
      }

      .popup-gb {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 16px;
        top: 21px;
      }

      .popup-content {
        padding: 29px 11px 4px 17px;

        .radio-tilte {
          font-size: 12px;
          color: #999999;
          padding: 0 0 12px 0;
        }

        .radio-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0 0 20px 0;

          .radio-left {
            font-size: 14px;
            font-weight: 500;
            color: #333333;
          }

          .radio-right {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border: 1px solid #CCCCCC;
          }
        }

        .instructions {
          padding: 15px 0 16px;
          font-size: 12px;
          color: #999999;
          border-top: 1px solid #EEEEEE;
        }

        .van-field {
          background: #F7F7F7;
          border-radius: 4px;
          margin: 0 0 18px 0;
        }

        .popup-but {
          width: 100%;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: linear-gradient(236deg, #FC5656 0%, #F70606 100%);
          border-radius: 100px;
          font-size: 16px;
          font-weight: 600;
          color: #FFFFFF;
        }
      }
    }

    .pop-box {
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;

      .pop-info {
        width: 260px;
        height: 305px;
        background: #FFFFFF;
        border-radius: 10px;
        box-sizing: border-box;
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        div {
          text-align: center;
        }

        .codeimg {
          margin: 16px auto;
        }
      }
    }
  }
</style>
